import { createOwnUpComponent, fonts } from '@rategravity/own-up-component-library';
import React from 'react';
import GreenCheckmark from '../../../images/icons/green-checkmark.svg';
import { colors } from '../../../modules/colors';

const HeroListWrapper = createOwnUpComponent('div', {
  width: '100%',
  display: 'block',
  paddingLeft: '30px',
  variants: {
    mediumAndDown: {
      padding: 'auto',
      display: 'flex',
      justifyContent: 'center'
    }
  }
});

const HeroListUl = createOwnUpComponent('ul', {
  ...fonts.GRAPHIK_REGULAR,
  margin: '0 0 30px -40px',
  fontSize: '16px',
  fontFamily: "'Graphik-Regular', 'Arial', 'sans-serif'",
  lineHeight: '25px',
  color: colors.PRIMARY,
  paddingInlineStart: 0,
  '& > li': {
    listStyleImage: `url(${GreenCheckmark})`
  },
  variants: {
    xsmall: {
      margin: '0 20px 30px 0',
      fontSize: '15px'
    },
    large: {
      margin: '0 0 30px 0',
      fontSize: '19px'
    }
  }
});

export const HeroList = ({ bullets }: { bullets: string[] }) => (
  <HeroListWrapper>
    <HeroListUl>
      {bullets.map((bullet, i) => (
        <li key={`bullet-${i}`}>
          <span style={{ paddingLeft: '12px' }}>{bullet}</span>
        </li>
      ))}
    </HeroListUl>
  </HeroListWrapper>
);
