import { createOwnUpComponent, createOwnUpStyle } from '@rategravity/own-up-component-library';
import React from 'react';
import { CallToActionButtonHero } from '../../../components/button';
import { useMixpanel } from '../../../hooks/use-mixpanel';

const AnchorStyle = createOwnUpStyle({
  margin: '0 25px 20px 0',
  textDecoration: 'none',
  textAlign: 'center',
  variants: {
    xsmall: {
      width: '100%'
    },
    mediumAndDown: {
      margin: '0 0 20px'
    }
  }
});

const Anchor = createOwnUpComponent('a', AnchorStyle);

const AnchoredButton = ({ action }: { action: 'Buying' | 'Refinance' }) => {
  const mixpanel = useMixpanel();
  return (
    <Anchor
      href={
        action === 'Refinance'
          ? `${process.env.GATSBY_ONBOARDING}/${action.toLocaleLowerCase()}`
          : `${process.env.GATSBY_DISCOVER}/${action.toLocaleLowerCase()}`
      }
      onClick={() => {
        mixpanel.track('Click discover CTA', {
          'page-location': `home-hero-${action === 'Buying' ? 'buying' : 'refi'}`
        });
      }}
      aria-label={`${action} with Own Up!`}
    >
      <CallToActionButtonHero>
        {action === 'Buying' ? action : 'Refinancing'}
      </CallToActionButtonHero>
    </Anchor>
  );
};

const CallToActionWrapperStyle = createOwnUpStyle({
  display: 'flex',
  variants: {
    mediumAndDown: {
      flexDirection: 'column',
      alignItems: 'center'
    }
  }
});

const CallToActionWrapper = createOwnUpComponent('section', CallToActionWrapperStyle);

export const CallToAction = () => {
  return (
    <CallToActionWrapper>
      <AnchoredButton action="Buying" />
      <AnchoredButton action="Refinance" />
    </CallToActionWrapper>
  );
};
