import loadable from '@loadable/component';
import { useTrackingInstance } from '@rategravity/1pt-lib';
import { ImageQuery, ImageWithContent } from '@rategravity/marketing-components';
import { buildMediaQueries } from '@rategravity/marketing-components';
import { createOwnUpComponent, OwnUpBox, OwnUpHeader } from '@rategravity/own-up-component-library';
import { SplitFactory } from '@splitsoftware/splitio-react';
import { graphql } from 'gatsby';
import React from 'react';
import styled from 'styled-components';
import { Divider } from '../components/divider';
import { CallToAction } from '../components/home/call-to-action';
import { HeroList } from '../components/home/hero-list';
import { IntroImage } from '../components/intro-image';
import { Layout } from '../components/layout';
import { Reviews } from '../components/reviews-featured';
import {
  Highlight,
  PrimaryText,
  SectionHeader,
  SectionHeaderMixin
} from '../components/typography';
import { useMrc } from '../hooks/use-mrc';
import GreenDotsBlob3SVG from '../images/green-dots-blob-3.svg';
import RateSpreadGraphSVG from '../images/home/rate-spread-graph.svg';

const AndrewImage = loadable(() => import('../components/home/grey-feature-section'), {
  resolveComponent: (components) => components.AndrewImage
});

const FAQSection = loadable(() => import('../components/home/faq-section'), {
  resolveComponent: (components) => components.FAQSection
});

const GreyFeatureSection = loadable(() => import('../components/home/grey-feature-section'), {
  resolveComponent: (components) => components.GreyFeatureSection
});

const GreyFieldWithPhoto = loadable(() => import('../components/grey-field-with-photo'), {
  resolveComponent: (components) => components.GreyFieldWithPhoto
});

const PressMentions = loadable(() => import('../components/press'), {
  resolveComponent: (components) => components.PressMentions
});

const QueriedPress = loadable(() => import('../components/press'), {
  resolveComponent: (components) => components.QueriedPress
});

const ResponsiveSpacer = loadable(() => import('../components/layout/spacers'), {
  resolveComponent: (components) => components.ResponsiveSpacer
});

const ReviewsFeatured = loadable(() => import('../components/reviews-featured'), {
  resolveComponent: (components) => components.ReviewsFeatured
});

const Spacer = loadable(() => import('../components/layout/spacers'), {
  resolveComponent: (components) => components.Spacer
});

const SectionLabel = loadable(() => import('../components/section-label'), {
  resolveComponent: (components) => components.SectionLabel
});

const ValuePropSection = loadable(() => import('../components/home/value-prop-section'), {
  resolveComponent: (components) => components.ValuePropSection
});

const RateSpreadGraphWrapper = createOwnUpComponent(OwnUpBox, {
  display: 'flex',
  flexDirection: 'column',
  width: '90%',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '30px',
  margin: 'auto',
  variants: {
    large: {
      height: '636px'
    }
  }
});

const RateSpreadGraphTextWrapper = createOwnUpComponent(OwnUpBox, {
  padding: '0 60px',
  variants: {
    mediumAndDown: {
      padding: '0 40px'
    }
  }
});

const RateSpreadGraph = () => (
  <RateSpreadGraphWrapper>
    <img
      style={{
        maxWidth: '100%',
        marginBottom: '28px'
      }}
      src={RateSpreadGraphSVG}
      alt="Graph showing that there’s a half a percent difference between the best and worst rates for the same borrower."
    />
    <RateSpreadGraphTextWrapper>
      <PrimaryText>
        There’s a <b>0.50%</b> difference between the <b>best</b> and <b>worst</b> rate for the same
        borrower.
      </PrimaryText>
    </RateSpreadGraphTextWrapper>
  </RateSpreadGraphWrapper>
);

export const query = graphql`
  {
    images: allFile(filter: { relativePath: { regex: "/home//" }, extension: { ne: "svg" } }) {
      nodes {
        relativePath
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: BLURRED)
        }
      }
    }
  }
`;

// style overrides

const sectionStyle = {
  margin: '0 30px'
};

const imagePadding = {
  variants: {
    smallAndDown: {
      padding: '30px 0'
    }
  }
};

const contentStyle = {
  variants: {
    large: {
      marginLeft: '40px'
    }
  }
};

const HomeIntro = ({ data }: { data: ImageQuery }) => {
  const Header = styled(OwnUpHeader)`
    ${SectionHeaderMixin}
    letter-spacing: 'normal';
    ${buildMediaQueries('mediumAndDown', {
      textAlign: 'center'
    })}
    ${buildMediaQueries('smallAndDown', {
      fontSize: '32px'
    })}
  `;

  const LargeHeaderWrapper = styled('div')`
    ${buildMediaQueries('smallAndDown', {
      display: 'none'
    })}
  `;

  const SmallHeaderWrapper = styled('div')`
    ${buildMediaQueries(['medium', 'large'], {
      display: 'none'
    })}
  `;

  const HomeIntroImage = <IntroImage imageData={data} imageName="new-homeowners.jpg" />;
  const HomeIntroContent = () => (
    <>
      <Header variant="title">
        <LargeHeaderWrapper>
          Find your best mortgage.
          <br />
          <Highlight>Save time and money.</Highlight>
        </LargeHeaderWrapper>
        <SmallHeaderWrapper>
          Find your best mortgage. <Highlight>Save time and money.</Highlight>
        </SmallHeaderWrapper>
      </Header>
      <HeroList
        bullets={[
          'Shop a marketplace of top lenders',
          'Zero unwanted calls or emails',
          'No impact to your credit'
        ]}
      />
      <CallToAction />
    </>
  );

  return (
    <ImageWithContent
      backgroundImage={GreenDotsBlob3SVG}
      Image={HomeIntroImage}
      label="intro"
      sectionStyle={sectionStyle}
      imagePadding={imagePadding}
      contentStyle={contentStyle}
    >
      <HomeIntroContent />
    </ImageWithContent>
  );
};
const GreyFieldContent = (
  <>
    <SectionHeader>Know more, save more</SectionHeader>
    <PrimaryText>
      Rates vary. The range can be half a percent or more -- that’s tens of thousands in savings. We
      benchmark real offers from thousands of lenders for your scenario, so you have the data you
      need to know you&#39;re getting a great deal.
    </PrimaryText>
  </>
);

const AppContents = ({ data }: { data: ImageQuery }) => {
  const showMrc = useMrc();
  return showMrc ? (
    <Layout>
      <div>MRC Content Placeholder</div>
    </Layout>
  ) : (
    <Layout>
      <HomeIntro data={data} />
      <Divider />
      <ResponsiveSpacer />
      <GreyFieldWithPhoto content={GreyFieldContent}>
        <RateSpreadGraph />
      </GreyFieldWithPhoto>
      <ValuePropSection />
      <GreyFeatureSection>
        <AndrewImage imageData={data} />
      </GreyFeatureSection>
      <Spacer />
      <SectionLabel>FAQS</SectionLabel>
      <FAQSection />
      <Divider />
      <ResponsiveSpacer />
      <SectionLabel>HEAR FROM OUR CUSTOMERS</SectionLabel>
      <ReviewsFeatured>
        <Reviews imageData={data} />
      </ReviewsFeatured>
      <Divider />
      <PressMentions>
        <QueriedPress />
      </PressMentions>
    </Layout>
  );
};

export const App = ({ data }: { data: ImageQuery }) => {
  const trackingInstance = useTrackingInstance();
  const splitUrl = process.env.SPLIT_URL || 'https://staging.split.ownup.com';
  const splitConfig = {
    core: {
      authorizationKey: process.env.SPLIT_API_KEY || 'qdjduua8u5orl12gguii27vam9g73rccr5lf',
      key: trackingInstance.getAnonymousId()!,
      trafficType: 'Anonymous'
    },
    urls: {
      sdk: `${splitUrl}/sdk-api`,
      events: `${splitUrl}/events-api`,
      auth: `${splitUrl}/auth-api`
    }
  };

  return (
    <SplitFactory config={splitConfig}>
      <AppContents data={data} />
    </SplitFactory>
  );
};

export default App;
